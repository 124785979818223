import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';




const DisclamerDialog = (props) => {
    const { t, i18n } = useTranslation('translations');
    const [open, setOpen] = React.useState(false);
    function changeLanguageHandler(lang) {
        i18n.changeLanguage(lang)
        props.LanguageChangeCallback?.apply(this, [lang]);
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.closecallback?.apply();
    };

    const handleSave = () => {

        handleClose();
    };

    useEffect(() => {
        if (props.open)
            handleOpen();
    }, [props.open])


    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle dangerouslySetInnerHTML={
                    { __html: t('Disclaimer', { interpolation: { escapeValue: false } }) }
                } ></DialogTitle>
                <DialogContent>
                    <DialogContentText dangerouslySetInnerHTML={
                        { __html: t('DisclaimerText', { interpolation: { escapeValue: false } }) }
                    } >

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                {!i18n.language.toString().toLowerCase().includes("en")&&<Button onClick={() => changeLanguageHandler("en")} variant="outlined">EN</Button>}
                    {!i18n.language.toString().toLowerCase().includes("th")&&<Button onClick={() => changeLanguageHandler("th")}variant="outlined">TH</Button>}
                     <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleSave} variant="outlined">{t("Accept")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DisclamerDialog
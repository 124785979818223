import { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'



const phoneInput = (props, ref) => {


  return (

    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      size='small'
      margin="dense"
      variant='standard'
      name='phone'
    />
  )
}
export default forwardRef(phoneInput)
window.calculateAge = function (dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
window.calculateAgeMonth = function (dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        return 0;
    }
    else
        return m;
}

window.calculateBMI = function (weight, height) {
    return (weight / ((height * height)
        / 10000)).toFixed(2);
}

var old_log = console.log;
console.logitem = function () {
    function clearProto(obj) {
        if (obj && typeof (obj) === "object") {
            var temp = JSON.parse(JSON.stringify(obj));
            temp.__proto__ = null;
            for (var prop in temp)
                temp[prop] = clearProto(temp[prop]);
            return temp;
        }
        return obj;
    }
    for (var i = 0; i < arguments.length; ++i)
        arguments[i] = clearProto(arguments[i]);
    old_log.apply(console, arguments);
}
window.skipitem = undefined;
window.unCheckAll = function (skipitem) {
    var x = document.querySelectorAll("input[type='checkbox']");
    if (skipitem) window.skipitem = skipitem;
    for (var o of x) {

        o.checked = false;
        o.skipitem = skipitem;
        o.removeEventListener('click', window.unCheckSkip)
        o.addEventListener('click', window.unCheckSkip)
    }
    var node = document.querySelector(`input[name='${window.skipitem}']`);
    if (node && skipitem) node.checked = true;
}

window.unCheckSkip = function (e) {
    var skipitem = e.currentTarget.skipitem;
    if (!skipitem) return;
    var node = document.querySelector(`input[name='${skipitem}']`);
    if (node) node.checked = false;
}
Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}
import React, { useState, useEffect,useRef } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Input from 'react-phone-number-input/input'
import CustomPhoneNumber from './PhoneNumber'


const PersonaldataDialog = (props) => {
    const { t, i18n } = useTranslation('translations');
    const [open, setOpen] = React.useState(false);
    const [telValue, setTelValue] = React.useState();
    const [emailValue, setEmailValue] = React.useState("");
    const inputRef = useRef();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.closecallback?.apply();
    };

    const handleSave = () => {
        var data = {};
        data.tel = telValue;
        data.email = emailValue;
        props.savecallback?.apply(this, [data]);
        handleClose();
    };

    useEffect(() => {
        if (props.open)
            handleOpen();
    }, [props.open])


    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t("Save Personal data?")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("savepersonaldatadescription")}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={emailValue}
                        onChange={(newValue) => setEmailValue(newValue.target.value)}
                        label={t("Email Address")}
                        type="email"
                        fullWidth
                        variant="standard"

                    />
                    <Input
                        country="TH"
                        margin="dense"
                        fullWidth
                        label={t("Telephone")}
                        variant="standard"
                        value={telValue}
                        onChange={setTelValue}
                        inputComponent={CustomPhoneNumber}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("Cancel")}</Button>
                    <Button onClick={handleSave}>{t("Save")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PersonaldataDialog
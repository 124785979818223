import React, { useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

export default function ErrorDialog(props) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [timer, setTimer] = useState(null);
    const { t } = useTranslation('translations');

    const handleOpen = () => {
        setIsModalVisible(true);
        setTimer(
            setTimeout(() => {
                setIsModalVisible(false);
            }, 3000)
        );
    };

    const handleClose = () => {

        setIsModalVisible(false);
        timer && clearTimeout(timer);

    };
    useEffect(() => {
        if (props.message)
            handleOpen();
    }, [props.message])

    return (
        <Box sx={{ width: '100%' }}>
            <Collapse in={isModalVisible}>
                <Alert color='error'
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {t(props.message)}
                </Alert>
            </Collapse>

        </Box>
    );
}
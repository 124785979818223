import axios from 'axios';

var api = {

    getPageByName: function (pageName, includeitem) {
        var param = {
            pageName: pageName,
            includeItem: includeitem
        };
        return axios.post(process.env.REACT_APP_DOMAIN + '/getsmdfieldsv2/getsmd_pagebyname', { data: param })
            .then(response => response.data.data.page)
            .then((data) => {
                if (data)
                    return data;
            });
    },
    getTopicList: function (age,gender) {
        var param = {
            age:age,
            gender:gender
         };
        return axios.post(process.env.REACT_APP_DOMAIN + '/getsmdfieldsv2/getsmd_topic', { data: param })
            .then(response => response.data.data.page)
            .then((data) => {
                if (data)
                    return data;
            });
    },
    getPageById: function (topic_id,page_id,age,gender) {
        var param = {
            topic_id:topic_id,
            page_id:page_id,
            age:age,
            gender:gender
         };
        return axios.post(process.env.REACT_APP_DOMAIN + '/getsmdfieldsv2/getsmd_pagebyidandtopicid', { data: param })
            .then(response => response.data.data.page)
            .then((data) => {
                if (data)
                    return data;
            });
    },
    saveResult: function (destination_logic,user_id,self_exam, answer, age, gender,currenttopic_id,hospital,chat_room_id) {
        var param = {
            user_id:user_id,
            self_exam:self_exam,
            age:age,
            gender:gender,
            answer:answer,
            currenttopic_id:currenttopic_id,
            hospital:hospital,
            chat_room_id:chat_room_id
         };
        return axios.post(process.env.REACT_APP_DOMAIN + '/savesmdresult/'+destination_logic, { data: param })
            .then(response => response.data.data)
            .then((data) => {
                if (data)
                    return data;
            });
    },
    sendResult: function (result) {
        var param = result;
        return axios.post(process.env.REACT_APP_DOMAIN + '/getsmdfieldsv2/sendresult', { data: param })
            .then(response => response.data.data)
            .then((data) => {
                if (data)
                    return data;
            });
    },
    getResult: function (transaction_id) {
        var param = {
            transaction_id:transaction_id
            
         };
        return axios.post(process.env.REACT_APP_DOMAIN + '/getsmdfieldsv2/getresult', { data: param })
            .then(response => response.data.data)
            .then((data) => {
                if (data)
                    return data;
            });
    },
    searchResult: function (param) {
    
        return axios.post(process.env.REACT_APP_DOMAIN + '/getsmdfieldsv2/searchresult', { data: param })
            .then(response => response.data.data)
            .then((data) => {
                if (data)
                    return data;
            });
    },
}



export default api
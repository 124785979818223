import './i18n';
import { withTranslation  } from 'react-i18next'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Smdquestion from "./components/smdquestion";
import Smdresult from "./components/smdresult";
import Smdsearch from "./components/smdsearch";
import './App.css';


function App({ t }) {
  return (

    <>
    {/* This is the alias of BrowserRouter i.e. Router */}
    <Router>
      
      <Routes>

        <Route exact path="/"  element={<Smdquestion/>} />
        <Route exact path="/search"  element={<Smdsearch/>} />
        <Route exact path="/result/:id"  element={<Smdresult/>} />
        <Route exact path="/:view/:id"  element={<Smdresult/>} />

      </Routes>
    </Router>
  </>

  );
}

export default withTranslation()(App)